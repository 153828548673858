<!--<template>-->
<!--  <page-container :title="pageTitle" :breadcrumb="breadcrumb">-->
<!--    <div class="box-container" style="margin-top: 10px;">-->
<!--      <div class="box-container-inner">-->
<!--        <div class="table-header">-->
<!--          <h3 class="table-title">企业基本信息</h3>-->
<!--          <div class="table-btns">-->
<!--            <a-button type="primary" @click="showModal('edit')">-->
<!--              <a-icon type="edit"></a-icon>-->
<!--              编辑-->
<!--            </a-button>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="simple-query" style="margin-top: 5px">-->
<!--          <a-form-model ref="modalParams" :model="modalParams" layout="inline" class="query-form">-->
<!--            <a-form-model-item label="企业名称" prop="name">-->
<!--              <a-input v-model="modalParams.unit_name" style="width: 200px" :disabled="true"></a-input>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="统一社会信用代码" prop="name">-->
<!--              <a-input v-model="modalParams.unit_code" style="width: 200px" :disabled="true"></a-input>-->
<!--            </a-form-model-item>-->

<!--            <a-form-model-item label="营业执照" prop="license"  :labelCol="labelCol" :wrapperCol="wrapperCol">-->
<!--              <file-list :fileList="modalParams.license"></file-list>-->
<!--            </a-form-model-item>-->
<!--          </a-form-model>-->
<!--        </div>-->
<!--        <a-divider/>-->
<!--        <div class="table-header">-->
<!--          <h3 class="table-title">项目负责人</h3>-->
<!--        </div>-->
<!--        <div class="simple-query" style="margin-top: 5px">-->
<!--          <a-form-model ref="modalParams" :model="modalParams" layout="inline" class="query-form">-->
<!--            <a-form-model-item label="姓名" prop="name">-->
<!--              <a-input v-model="modalParams.manager_name" style="width: 200px" :disabled="true"></a-input>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="性别" prop="name">-->
<!--              <a-input v-model="modalParams.manager_sexual" style="width: 200px" :disabled="true"></a-input>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="年龄" prop="name">-->
<!--              <a-input v-model="modalParams.manager_age" style="width: 200px" :disabled="true"></a-input>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="政治面貌" prop="name">-->
<!--              <a-input v-model="modalParams.manager_political" style="width: 200px" :disabled="true"></a-input>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="学历" prop="name">-->
<!--              <a-input v-model="modalParams.manager_education" style="width: 200px" :disabled="true"></a-input>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="职务" prop="name">-->
<!--              <a-input v-model="modalParams.manager_duties" style="width: 200px" :disabled="true"></a-input>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="联系方式" prop="name">-->
<!--              <a-input v-model="modalParams.manager_phone" style="width: 200px" :disabled="true"></a-input>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="备注" prop="name">-->
<!--              <a-input v-model="modalParams.manager_remark" style="width: 200px" :disabled="true"></a-input>-->
<!--            </a-form-model-item>-->
<!--          </a-form-model>-->
<!--        </div>-->
<!--        <a-divider/>-->
<!--        <div class="table-header">-->
<!--          <h3 class="table-title">物业服务投诉电话</h3>-->
<!--        </div>-->

<!--        <div class="simple-query" style="margin-top: 5px">-->
<!--          <a-form-model ref="modalParams" :model="modalParams" layout="inline" class="query-form">-->
<!--            <a-form-model-item label="物业服务投诉电话" prop="phone">-->
<!--              <a-input v-model="modalParams.complaints_hotline" style="width: 200px" :disabled="true"></a-input>-->
<!--            </a-form-model-item>-->
<!--          </a-form-model>-->
<!--        </div>-->
<!--        <a-divider/>-->
<!--        <div class="table-header">-->
<!--          <h3 class="table-title">合同期限</h3>-->
<!--        </div>-->
<!--        <div class="simple-query" style="margin-top: 30px">-->
<!--          <a-form-model ref="modalParams" :model="modalParams" layout="inline" class="query-form">-->
<!--            <a-form-model-item label="合同期限" prop="time_interval">-->
<!--              <a-range-picker v-model="modalParams.time_interval" disabled="true">-->
<!--              </a-range-picker>-->
<!--            </a-form-model-item>-->
<!--          </a-form-model>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash;    新建、修改违约页面&ndash;&gt;-->
<!--    <a-modal v-model="modalVisible" title="编辑" :width="900" :dialog-style="{top: '25px'}"-->
<!--             :body-style="modalBodyStyle">-->
<!--      <template slot="footer">-->
<!--        <a-button v-if="modalType=='add'||modalType=='edit'" @click="modalCancel">取消</a-button>-->
<!--        <a-button type="primary" @click="modalConfirm">提交</a-button>-->
<!--      </template>-->
<!--      <a-form-model ref="modalParams" :model="modalParams" :rules="modalRules" layout="inline" class="form-in-twocolumns"-->
<!--                    :label-col="{span:9}" :wrapper-col="{span:14}">-->

<!--        <a-form-model-item label="企业名称" prop="name">-->
<!--          <a-input v-model="modalParams.unit_name" style="width: 200px" placeholder="请输入企业名称"></a-input>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="统一社会信用代码" prop="name">-->
<!--          <a-input v-model="modalParams.unit_code" style="width: 200px" placeholder="请输入统一社会信用代码"></a-input>-->
<!--        </a-form-model-item>-->

<!--        <a-form-model-item label="营业执照" prop="license">-->
<!--          <a-upload name="licenseList" :headers="uploadHeaders" :data="uploadAnnexData" action="/upload" :file-list="annexList" @change="annexUploaded">-->
<!--            <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>-->
<!--          </a-upload>-->
<!--        </a-form-model-item>-->

<!--        <a-divider/>-->
<!--        <a-form-model-item label="姓名" prop="name">-->
<!--          <a-input v-model="modalParams.manager_name" style="width: 200px" placeholder="请输入姓名"></a-input>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="性别" prop="name">-->
<!--          <a-input v-model="modalParams.manager_sexual" style="width: 200px" placeholder="请输入性别"></a-input>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="年龄" prop="name">-->
<!--          <a-input v-model="modalParams.manager_age" style="width: 200px" placeholder="请输入年龄"></a-input>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="政治面貌" prop="name">-->
<!--          <a-input v-model="modalParams.manager_political" style="width: 200px" placeholder="请输入政治面貌"></a-input>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="学历" prop="name">-->
<!--          <a-input v-model="modalParams.manager_education" style="width: 200px" placeholder="请输入学历"></a-input>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="职务" prop="name">-->
<!--          <a-input v-model="modalParams.manager_duties" style="width: 200px" placeholder="请输入职务"></a-input>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="联系方式" prop="name">-->
<!--          <a-input v-model="modalParams.manager_phone" style="width: 200px" placeholder="请输入联系方式"></a-input>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="备注" prop="name">-->
<!--          <a-input v-model="modalParams.manager_remark" style="width: 200px" placeholder="请输入备注"></a-input>-->
<!--        </a-form-model-item>-->

<!--        <a-divider/>-->
<!--        <a-form-model-item label="物业服务投诉电话" prop="phone">-->
<!--          <a-input v-model="modalParams.complaints_hotline" style="width: 200px" placeholder="请输入物业服务投诉电话"></a-input>-->
<!--        </a-form-model-item>-->

<!--        <a-divider/>-->
<!--        <a-form-model-item label="合同起止时间" prop="happen_time">-->
<!--          <a-range-picker v-model="modalParams.time_interval">-->
<!--          </a-range-picker>-->
<!--        </a-form-model-item>-->
<!--      </a-form-model>-->
<!--    </a-modal>-->
<!--  </page-container>-->
<!--</template>-->

<!--<script>-->
<!--import moment from "moment";-->
<!--import {mapState} from "vuex";-->
<!--import {getCache} from "U/index";-->
<!--import FileList from '@/components/fileList/FileList'-->

<!--export default {-->
<!--  name: "serveEnterpriseInfo",-->
<!--  components: {-->
<!--    FileList-->
<!--  },-->
<!--  data() {-->
<!--    return {-->
<!--      modalVisible: false,-->
<!--      modalParams: {-->
<!--        id:'',-->
<!--        name:'保利',-->
<!--        credit_code:'33333333333333333',-->
<!--        license_path:'',-->
<!--        manager_name:'皮特',-->
<!--        manager_sexual:'男',-->
<!--        manager_age:40,-->
<!--        manager_political:'党员',-->
<!--        manager_education:'党员',-->
<!--        manager_duties:'党员',-->
<!--        manager_phone:'13655552545',-->
<!--        manager_remark:'无',-->
<!--        complaints_hotline:'13655552545',-->
<!--        time_interval:['2021-01-01','2025-01-01'],-->
<!--        license:['1111.pdf']-->
<!--      },-->
<!--      labelCol: {-->
<!--        xs: {span: 24},-->
<!--        sm: {span: 5},-->
<!--      },-->
<!--      wrapperCol: {-->
<!--        xs: {span: 24},-->
<!--        sm: {span: 16},-->
<!--      },-->
<!--      modalBodyStyle: {-->
<!--        maxHeight: '560px',-->
<!--        overflowY: 'auto',-->
<!--      },-->
<!--      // formRules: {-->
<!--      //   systemnameid: [{required: true, message: '请选择系统名称'}],-->
<!--      //   eventstarttime: [{required: true, message: '请选择报警时间'}],-->
<!--      //   eventcode: [{required: true, message: '请选择报警类型'}],-->
<!--      //   location: [{required: true, message: '请输入位置/编码'}],-->
<!--      //   arriveman: [{required: true, message: '请输入到达人员'}],-->
<!--      //   arrivetime: [{required: true, message: '请输入到达时间'}],-->
<!--      //   recordman: [{required: true, message: '请输入记录人'}],-->
<!--      //   timerange: [{required: true, message: '请输入确警时长'}],-->
<!--      //   feedback: [{required: true, message: '请输入到场反馈'}],-->
<!--      //   description: [{required: true, message: '请输入情况描述'}],-->
<!--      //   eventstatus: [{required: true, message: '请输入完成标志'}],-->
<!--      //   measure: [{required: true, message: '请输入处理措施'}],-->
<!--      //   plannedwork: [{required: true, message: '请选择是否为计划性作业'}],-->
<!--      //   specialeventnum: [{required: true, message: '请输入特别事件报告编号'}],-->
<!--      //   causeclassify: [{required: true, message: '请选择原因分类'}],-->
<!--      // },-->
<!--      // monitorpointNameList: [],-->
<!--    }-->
<!--  },-->
<!--  watch: {},-->
<!--  computed: {-->
<!--    pageTitle() {-->
<!--      return "服务企业简介"-->
<!--    },-->
<!--    breadcrumb() {-->
<!--      const pages = [{name: "服务企业简介", path: ""}]-->
<!--      pages.push({name: this.pageTitle, path: ""})-->
<!--      return pages-->
<!--    },-->
<!--  },-->
<!--  created() {-->
<!--  },-->
<!--  methods: {-->
<!--    showModal(type, value, record) {-->
<!--      this.modalType = type;-->
<!--      if (type == 'add') {-->
<!--        this.modalVisible = true;-->
<!--        this.modalForm.id=0-->
<!--        this.modalForm.service_content=''-->
<!--        this.modalForm.service_standard= ''-->
<!--        this.modalForm.fees_form= ''-->
<!--        this.modalForm.fees_standard= ''-->
<!--        this.modalForm.fees_type= ''-->
<!--        this.modalForm.annex= ''-->
<!--      } else {-->
<!--        this.modalVisible = true;-->
<!--        this.modalForm.id=record.id-->
<!--        this.modalForm.service_content=record.service_content-->
<!--        this.modalForm.service_standard= record.service_standard-->
<!--        this.modalForm.fees_form= record.fees_form-->
<!--        this.modalForm.fees_standard= record.fees_standard-->
<!--        this.modalForm.fees_type= record.fees_type-->
<!--        this.modalForm.annex=record.annex-->
<!--      }-->
<!--    },-->
<!--    modalCancel(){-->
<!--      this.modalVisible=false-->
<!--    },-->
<!--    modalConfirm() {-->
<!--      if (this.modalType == 'add' || this.modalType == 'edit') {-->
<!--        this.$refs.modalForm.validate(valid => {-->
<!--          if (valid) {-->
<!--            this.addOrEdit();-->
<!--          } else {-->
<!--            return false;-->
<!--          }-->
<!--        })-->
<!--      } else {-->
<!--        this.modalVisible = false;-->
<!--      }-->
<!--    },-->

<!--  }-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->

<!--</style>-->

<template>
  <page-container title="服务企业单位情况" :breadcrumb="breadcrumb">
    <!--    查询栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="企业名称" prop="name">
              <a-input v-model.trim="queryParams.name" placeholder="企业名称"></a-input>
            </a-form-model-item>

            <a-form-model-item label="服务项目" prop="userdepid">
              <a-cascader :disabled="modalType==='detail'" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }"
                          v-model="queryParams.selectDeptList"
                          :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]"
                          :load-data="loadChildrenDept" placeholder="请选择服务项目" :change-on-select="true"
                          :allow-clear="false"></a-cascader>
            </a-form-model-item>

            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="企业信用代码" prop="credit_code">
              <a-input v-model.trim="queryParams.credit_code" placeholder="企业信用代码"></a-input>
            </a-form-model-item>
            <a-form-model-item label="项目负责人姓名" prop="manager_name">
              <a-input v-model.trim="queryParams.manager_name" placeholder="请输入项目负责人姓名,"></a-input>
            </a-form-model-item>
            <a-form-model-item label="项目负责人职务" prop="manager_duties">
              <a-input v-model.trim="queryParams.manager_duties" placeholder="请输入项目负责人职务"></a-input>
            </a-form-model-item>
            <a-form-model-item label="项目负责人联系方式" prop="manager_phone">
              <a-input v-model.trim="queryParams.manager_phone" placeholder="请输入项目负责人联系方式"></a-input>
            </a-form-model-item>
            <a-form-model-item label="物业服务投诉电话" prop="complaints_hotline">
              <a-input v-model.trim="queryParams.complaints_hotline" placeholder="请输入物业服务投诉电话"></a-input>
            </a-form-model-item>
            <a-form-model-item label="合同期限" prop="time_interval">
              <a-range-picker v-model="queryParams.time_interval">
              </a-range-picker>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <!--    展示列表栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">服务企业单位列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')">
              <a-icon type="plus"></a-icon>
              新建
            </a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false"  :customRow="handleClickRow">
          <span slot="people_type" slot-scope="text" class="serveEnterpriseInfo_people_type-view">
            <span class="text">{{ text == '0' ? '物业' : '业主' }}</span>
          </span>
          <span slot="license" slot-scope="value, record" class="license-view">
              <a-tag v-if="record.license==='1'" class="text" @click.stop="downloadAnnexClick(record.license_path)">
                {{ record.license_path.split("/").pop()}}
              </a-tag>
            <a-tag v-else class="text red">
                无附件
              </a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`"
                      style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <!--    新建、修改服务企业页面-->
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :dialog-style="{top: '25px'}"
             :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalCancel">取消</a-button>
        <a-button v-if="modalType=='add'||modalType=='edit'" type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" layout="inline" class="form-in-twocolumns"
                    :label-col="{span:9}" :wrapper-col="{span:14}">

        <div class="table-header">
          <h3 class="table-title">企业基本信息</h3>
        </div>
        <a-form-model-item label="企业名称" prop="name">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.name" placeholder="请输入服务企业名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="服务项目" prop="userdepid">
          <a-cascader :disabled="modalType==='detail'" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }"
                      v-model="modalForm.selectDeptList"
                      :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]"
                      :load-data="loadChildrenDept" placeholder="请选择服务项目" :change-on-select="true"
                      :allow-clear="false">
          </a-cascader>
        </a-form-model-item>
        <a-form-model-item label="企业信用代码" prop="credit_code">
          <a-input :disabled="modalType==='detail'" v-model.trim="modalForm.credit_code" placeholder="服务企业信用代码"></a-input>
        </a-form-model-item>

        <a-form-model-item :label="modalType==='detail'?'上传营业执照':'上传营业执照(仅限图片)'" prop="license_path">
          <a-upload :disabled="modalType==='detail'" name="fileList" :headers="uploadHeaders" :data="uploadEnterpriseLicenseData" action="/upload"
                    :file-list="licensePathList" @change="licenseUploaded" accept=".jpg,.jpeg,.png" :beforeUpload="beforeUpload">
            <a-button>
              <a-icon type="upload"/>
              点击上传营业执照图片
            </a-button>
          </a-upload>
        </a-form-model-item>

        <a-divider/>
        <div class="table-header">
          <h3 class="table-title">项目负责人</h3>
        </div>
        <a-form-model-item label="项目负责人姓名" prop="manager_name">
          <a-input :disabled="modalType==='detail'" v-model.trim="modalForm.manager_name" placeholder="请输入项目负责人姓名"></a-input>
        </a-form-model-item>
        <a-form-model-item label="项目负责人性别" prop="manager_sexual">
          <a-select :disabled="modalType==='detail'" v-model="modalForm.manager_sexual">

            <a-select-option v-for="(item, index) in sexualList" :key="index" :value="parseInt(item.dickey,10)">
              {{ item.dicvalue }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="项目负责人年龄" prop="manager_age">
          <a-input :disabled="modalType==='detail'" v-model.trim="modalForm.manager_age" placeholder="请输入项目负责人年龄"></a-input>
        </a-form-model-item>
        <a-form-model-item label="项目负责人政治面貌" prop="manager_political">
          <a-select :disabled="modalType==='detail'" v-model="modalForm.manager_political">

            <a-select-option v-for="(item, index) in politicalRoleList" :key="index" :value="parseInt(item.dickey,10)">
              {{ item.dicvalue }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="项目负责人学历" prop="manager_education">
          <a-select :disabled="modalType==='detail'" v-model="modalForm.manager_education">

            <a-select-option v-for="(item, index) in educationRecordList" :key="index"
                             :value="parseInt(item.dickey,10)">{{ item.dicvalue }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="项目负责人职务" prop="manager_duties">
          <a-input :disabled="modalType==='detail'" v-model.trim="modalForm.manager_duties" placeholder="请输入项目负责人职务"></a-input>
        </a-form-model-item>
        <a-form-model-item label="项目负责人联系方式" prop="manager_phone">
          <a-input :disabled="modalType==='detail'" v-model.trim="modalForm.manager_phone" placeholder="请输入项目负责人联系方式"></a-input>
        </a-form-model-item>
        <a-form-model-item label="项目负责人备注" prop="manager_remark">
          <a-textarea :disabled="modalType==='detail'" v-model.trim="modalForm.manager_remark" placeholder="请输入项目负责人备注"></a-textarea>
        </a-form-model-item>
        <a-divider/>
        <div class="table-header">
          <h3 class="table-title">物业服务投诉电话</h3>
        </div>
        <a-form-model-item label="物业服务投诉电话" prop="complaints_hotline">
          <a-input :disabled="modalType==='detail'" v-model.trim="modalForm.complaints_hotline" placeholder="请输入物业服务投诉电话，如果是座机请加区号"></a-input>
        </a-form-model-item>
        <a-divider/>
        <div class="table-header">
          <h3 class="table-title">合同期限</h3>
        </div>
        <a-form-model-item label="合同期限" prop="time_interval">
          <a-range-picker :disabled="modalType==='detail'" v-model="modalForm.time_interval">
          </a-range-picker>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {
  addPerformanceServeEnterpriseInfo,
  getServeEnterpriseInfoListByCondition,
  deleteOneServeEnterpriseInfo,
  editServeEnterpriseInfo
} from 'A/performance'
import {getDictByDicType} from 'A/xtpz'
import {
  getItemFromArrayByKey,
} from 'U'
import moment from 'moment'
import {mapState} from "vuex";
import deptselect from '@/mixins/deptselect'
import {userdep} from "V/dataAnalysis/performance/minins/userdep";
import {mapGetters} from "vuex";

export default {
  name: 'serveEnterpriseInfo',
  mixins: [deptselect, userdep],
  data() {
    return {
      showAdvanced: false,
      moment,
      modalBodyStyle: {
        maxHeight: '650px',
        overflowY: 'auto',
      },
      breadcrumb: [
        {
          name: '业务管理',
          path: ''
        },
        {
          name: '履约管理',
          path: ''
        },
        {
          name: '履约数据',
          path: ''
        },
        {
          name: '服务企业',
          path: ''
        }
      ],
      licensePathList: [],
      queryParams: {
        name: '',
        credit_code: '',
        manager_name: '',
        manager_duties: '',
        manager_phone: '',
        complaints_hotline: '',
        time_interval: [],
        userdepid: "",
        selectDeptList: [],
      },
      tableColumns: [
        {
          title: '企业名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '企业信用代码',
          dataIndex: 'credit_code',
          key: 'credit_code'
        },
        {
          title: '营业执照',
          dataIndex: 'license',
          key: 'license',
          scopedSlots: { customRender: 'license' }
        },
        {
          title: '服务项目',
          dataIndex: 'userdepname',
          key: 'userdepname'
        },
        {
          title: '项目负责人',
          dataIndex: 'manager_name',
          key: 'manager_name'
        },
        {
          title: '项目负责人职务',
          dataIndex: 'manager_duties',
          key: 'manager_duties'
        },
        {
          title: '项目负责人联系方式',
          dataIndex: 'manager_phone',
          key: 'manager_phone'
        },
        {
          title: '物业服务投诉电话',
          dataIndex: 'complaints_hotline',
          key: 'complaints_hotline'
        },
        {
          title: '合同开始时间',
          dataIndex: 'begin_time',
          key: 'begin_time'
        },
        {
          title: '合同结束时间',
          dataIndex: 'end_time',
          key: 'end_time'
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: {customRender: 'operation'}
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        id: '',
        name: '',
        credit_code: '',
        license_path: '',
        manager_name: '',
        manager_sexual: '',
        manager_age: '',
        manager_political: '',
        manager_education: '',
        manager_duties: '',
        manager_phone: '',
        manager_remark: '',
        complaints_hotline: '',
        time_interval: [],
        userdepid: "",
        selectDeptList: [],
      },
      modalRules: {
        name: [{required: true, message: '请输入服务企业名称'}],
        userdepid: [{required: true, message: '请选择服务项目'}],
        ///^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/
        //不匹配_IOZSVa-z，2个字符，6个数字，不匹配_IOZSVa-z，最后十个字符
        credit_code: [
          {required: true, message: '请输入服务企业统一社会信用代码'},
          {
            validator: (rule, value, callback) => {
              if (/^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/.test(value)) {
                callback();
              } else {
                callback('请输入正确的企业统一社会信用代码')
              }
            }, trigger: 'blur'
          }
        ],
        manager_name: [{required: true, message: '请输入项目负责人姓名'}],
        manager_duties: [{required: true, message: '请输入项目负责人职务'}],
        manager_phone: [
          {required: true, message: '请输入项目负责人电话'},
          {
            validator: (rule, value, callback) => {
              if (/^1\d{10}$/.test(value)) {
                callback();
              } else {
                callback('请输入正确的手机号码')
              }
            }, trigger: 'blur'
          }
        ],
        //^(?:(0\d{2,3}-)?\d{7,8}|1[3-9]\d{9})$
        //complaints_hotline: [{required: true, message: '请输入服务热线'}],
        complaints_hotline: [
          {required: true, message: '请输入物业服务投诉电话,如果是座机请加区号，如010-5896489、0519-96856523'},
          {
            validator: (rule, value, callback) => {
              if (/^(?:(0\d{2,3}-)?\d{7,8}|1\d{10})$/.test(value)) {
                callback();
              } else {
                callback('请输入正确的物业服务投诉电话，如果是座机请加区号，如010-5896489、0519-96856523')
              }
            }, trigger: 'blur'
          }
        ],
        time_interval: [{required: true, message: '请选择合同期限'}],

      },
      selectedServeEnterpriseInfo: null,
      sexualList: [],
      politicalRoleList: [],
      educationRecordList: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadEnterpriseLicenseData']),
    modalTitle() {
      let title = '';
      if (this.modalType == 'add') {
        title = '新增';
      } else if (this.modalType == 'edit') {
        title = '修改';
      }  else if (this.modalType == 'detail') {
        title = '详情';
      }else {
        title = '';
      }
      return title;
    },
    query_begin_time() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[0]) {
        return moment(this.queryParams.time_interval[0]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    query_end_time() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[1]) {
        return moment(this.queryParams.time_interval[1]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    //添加费单时间
    add_begin_time() {
      if (this.modalForm.time_interval && this.modalForm.time_interval[0]) {
        return moment(this.modalForm.time_interval[0]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    add_end_time() {
      if (this.modalForm.time_interval && this.modalForm.time_interval[1]) {
        return moment(this.modalForm.time_interval[1]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
  },
  watch: {
    'modalForm.selectDeptList'(val) {
      if (val && val.length) {
        this.modalForm.userdepid = val.join('|')
        this.$refs.modalForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      } else {
        this.modalForm.userdepid = ''
      }
    },
    'queryParams.selectDeptList'(val) {
      if (val && val.length) {
        this.queryParams.userdepid = val.join('|')
        this.$refs.queryForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      } else {
        this.queryParams.userdepid = ''
      }
    },
  },

  created() {
    this.queryParams.userdepid=this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid
    this.init();
    this.getSexualTypes();
    this.getPoliticalRoleTypes();
    this.getEducationRecordTypes();
  },
  methods: {
    init() {
      this.getTableData();
    },
    //获取所有性别类型
    getSexualTypes() {
      let params = {
        dictype: '51'
      };
      getDictByDicType(params).then(res => {
        if (res && res.returncode == '0') {
          //console.log(res.item)
          this.sexualList = res.item ? res.item : []
        }
      })
    },
    //获取所有政治面貌类型
    getPoliticalRoleTypes() {
      let params = {
        dictype: '52'
      };
      getDictByDicType(params).then(res => {
        if (res && res.returncode == '0') {
          //console.log(res.item)
          this.politicalRoleList = res.item ? res.item : []
        }
      })
    },
    //获取所有学历类型
    getEducationRecordTypes() {
      let params = {
        dictype: '53'
      };
      getDictByDicType(params).then(res => {
        if (res && res.returncode == '0') {
          //console.log(res.item)
          this.educationRecordList = res.item ? res.item : []
        }
      })
    },
    resetQueryParams() {
      this.queryParams.selectDeptList = []
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      // let userdepid = this.queryParams.userdepid;
      // if (userdepid.indexOf('|') > -1) {
      //   userdepid = userdepid.substr(userdepid.lastIndexOf('|') + 1);
      // }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
        begin_time: this.query_begin_time,
        end_time: this.query_end_time,
        //userdep_id:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,
        //userdepid:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,
      };
      console.log(params)
      getServeEnterpriseInfoListByCondition(params).then(res => {
        this.tableLoading = false;
        console.log(res.item)
        if (res && res.returncode === '0') {
          //this.tableData = res.item ? res.item : []
          this.tableData = res.item?res.item.map(item => ({
            ...item,
            license: item.license_path===''?'0':'1'
          })):[]
          this.pagination.total = res.count;
        }
      }).catch(err => {
        console.log(err)
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;
      if (type == 'add') {
        this.modalVisible = true;
        this.modalForm.id = 0
        this.modalForm.name = ''
        this.modalForm.credit_code = ''
        this.modalForm.license_path = ''
        this.modalForm.manager_name = ''
        this.modalForm.manager_sexual = ''
        this.modalForm.manager_age = ''
        this.modalForm.manager_political = ''
        this.modalForm.manager_education = ''
        this.modalForm.manager_duties = ''
        this.modalForm.manager_phone = ''
        this.modalForm.manager_remark = ''
        this.modalForm.complaints_hotline = ''

        this.modalForm.time_interval = []
        this.modalForm.userdepid = ''
        this.modalForm.selectDeptList = []

        this.licensePathList = []
      } else {
        this.initUserDeptid(record.userdep_id)
        this.modalVisible = true;
        this.modalForm.id = record.id

        this.modalForm.name = record.name
        this.modalForm.credit_code = record.credit_code
        this.modalForm.license_path = record.license_path
        this.modalForm.manager_name = record.manager_name
        this.modalForm.manager_sexual = record.manager_sexual
        this.modalForm.manager_age = record.manager_age
        this.modalForm.manager_political = record.manager_political
        this.modalForm.manager_education = record.manager_education
        this.modalForm.manager_duties = record.manager_duties
        this.modalForm.manager_phone = record.manager_phone
        this.modalForm.manager_remark = record.manager_remark
        this.modalForm.complaints_hotline = record.complaints_hotline

        this.modalForm.time_interval[0] = record.begin_time
        this.modalForm.time_interval[1] = record.end_time
        this.modalForm.userdepid = record.userdep_id
        const file_name = record.license_path.split("/").pop();
        this.licensePathList = [{
          uid: 'filenameuid',
          name: file_name,
          status: 'done',
          url: record.license_path,
        }];
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.id);
      }).catch(()=>{
      });
    },
    delete(serveEnterpriseInfo_id) {
      if (serveEnterpriseInfo_id) {
        let params = {
          serveEnterpriseInfo_id
        };
        deleteOneServeEnterpriseInfo(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if (this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.addOrEdit();
          } else {
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    //添加或者编辑违约单
    addOrEdit() {
      this.showLoading();
      console.log(this.modalForm)
      let userdepid = this.modalForm.userdepid;
      if (userdepid.indexOf('|') > -1) {
        userdepid = userdepid.substr(userdepid.lastIndexOf('|') + 1);
      }
      let params = {
        name: this.modalForm.name,
        credit_code: this.modalForm.credit_code,
        license_path: this.modalForm.license_path,
        manager_name: this.modalForm.manager_name,
        manager_sexual: this.modalForm.manager_sexual,
        manager_age: this.modalForm.manager_age,
        manager_political: this.modalForm.manager_political,
        manager_education: this.modalForm.manager_education,
        manager_duties: this.modalForm.manager_duties,
        manager_phone: this.modalForm.manager_phone,
        manager_remark: this.modalForm.manager_remark,
        complaints_hotline: this.modalForm.complaints_hotline,

        begin_time: this.add_begin_time,
        end_time: this.add_end_time,
        userdep_id: userdepid
      };
      if (this.modalType == 'add') {
        console.log(params)
        addPerformanceServeEnterpriseInfo(params).then(
            this.addOrEditResult);
      } else if (this.modalType == 'edit') {
        params.id = this.modalForm.id;
        console.log(params)
        editServeEnterpriseInfo(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if (res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      } else {
        this.$message.error(res.errormsg || '操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      console.log(arr)
      this.selectedServeEnterpriseInfo = getItemFromArrayByKey(this.tableData, 'id', id);
      console.log(this.selectedServeEnterpriseInfo)
      if ('edit' == type) {
        this.showModal('edit', id, this.selectedServeEnterpriseInfo)
      } else if ('detail' == type) {
        this.showModal('detail', id, this.selectedServeEnterpriseInfo)
      } else if ('delete' == type) {
        this.deleteConfirm(id, this.selectedServeEnterpriseInfo)
      }
    },
    handleClickRow(record,index){
      console.log(record)
      return {
        on: {
          click: () => {
            this.operationClick({key:"detail-"+String(record.id)});
          }
        }
      }
    },
    //上传营业执照
    licenseUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      //console.log(fileList)
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.modalForm.license_path = file.response.urlPath;
        }
        return file;
      });
      this.licensePathList = fileList;
    },
    downloadAnnexClick(path){
      if(path!==''){
        const pageUrl = window.location.origin;
        const name = path.split("/").pop();
        const download_path=pageUrl+"/"+path;
        let a = document.createElement('a');
        a.href = download_path;
        a.download = name;
        a.click();
      }else{
        this.$message.error("文件为空！");
      }
    },
    beforeUpload(file){
      return new Promise((resolve, reject) => {
        const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg";
        if (!isJpgOrPng) {
          this.$message.error("上传文件格式只能是jpg/png/jpeg");
          return reject(false);
        }
        const isLimit = file.size / 1024 / 1024 < 5;
        if (!isLimit) {
          this.$message.error("上传图片大小不能超过 5MB!");
          return reject(false);
        }
        return resolve(true);
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}

.serveEnterpriseInfo_status-view {
  display: flex;
  align-items: center;

  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #65ff18;
    border-radius: 50%;

    &.red {
      background-color: #e70c0c;
    }
  }

  .text {
    display: inline-block;
    margin-left: 7px;
  }
}

.serveEnterpriseInfo_people_type-view {
  display: flex;
  align-items: center;

  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #1859ff;
    border-radius: 50%;

    &.green {
      background-color: #65ff18;
    }
  }

  .text {
    display: inline-block;
    margin-left: 7px;
  }
}
.license-view {
  display: flex;
  align-items: center;
  .text {
    display: inline-block;
    margin-left: 7px;
    //border: 1px solid black;
    color: #57c921;
    &.red {
      color: #e70c0c;
    }
  }
}
</style>